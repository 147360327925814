import i18n from './lang'
import { version } from '../package.json';

const gConfig = {

    get envName() {
        let nodeEnv = process.env.NODE_ENV;

        let hostname = window.location.hostname;
        if (hostname.startsWith('devlpwebportal') || hostname.startsWith('dev-lp-userapp')) {
            return "devAzure";
        }
        if (hostname.startsWith('testlpwebportal') || hostname.startsWith('test-lp-userapp')) {
            return "test";
        }
        if (hostname.startsWith('app.lazyphish.com') || hostname.startsWith('prodlpwebportal')) {
            return "production";
        }

        return nodeEnv;
    },

    get url() {
        if (this.envName === "devAzure")
            return "https://dev-lp-service.azurewebsites.net/api";

        if (this.envName === "test")
            return "https://test-lp-service.azurewebsites.net/api";

        if (this.envName === "production")
            return "https://service.lazyphish.com/api";

        if (this.envName === "dev")
            return "https://dev-lp-service.azurewebsites.net/api";
            //return "https://localhost:44324/api";
            //return "https://service.lazyphish.com/api";
        return "";
    },
    get appInsightsID() {
        if (this.envName.includes("dev")) {
            return "c6fbb1fe-165f-4d56-a192-021861ae612b";
        }
        if (this.envName === "test") {
            return "b8f055f0-7821-40ab-a6b5-06c3012d983a";
        }
        if (this.envName === "production") {
            return "f9773e95-80e8-45f5-9f50-8bc418e51d87";
        }
        return "";
    },
    get reCaptchaSiteKey() {
        if (this.envName.includes("dev")) {
            return "6Lea1T8aAAAAAISKuA0g6pDSlvf3b1fdHGrb7Mpu";
        }
        if (this.envName === "test") {
            return "6Lea1T8aAAAAAISKuA0g6pDSlvf3b1fdHGrb7Mpu";
        }
        if (this.envName === "production") {
            return "6Lf9FnoaAAAAAG_ZY7YMh-HCFpq-dG5xML6gcEIe";
        }
        return "";
    },

    get getPublicWebUrl() {
        return "https://lazyphish.com/";
    },

    get getPhishingReportingEmail() {
        if (this.envName.includes("dev")) {
            return "dev-report@lazyphish-service.com";
        }
        if (this.envName === "test") {
            return "test-report@lazyphish-service.com";
        }
        return "report@lazyphish-service.com";
    },

    get getMsOutlookManifestUrl() {
        return this.storageBaseUrl + "/public/plugins/msoutlook/" + "manifest.xml";
    },

    get appVersion() { return version; },

    get storageBaseUrl() {
        let url = "https://prodlpstorage.blob.core.windows.net";
        if (this.envName === "dev" || this.envName === "devAzure")
            url = "https://devlpstorage.blob.core.windows.net";
        if (this.envName === "test")
            url = "https://testlpstorage.blob.core.windows.net";
        return url;
    },

    get uiLanguages() {
        return [
            { value: 'en', label: 'EN' },
            { value: 'cs', label: 'CS' },
            { value: 'de', label: 'DE' },
            { value: 'sk', label: 'SK' },
        ]
    },


    //-----------------------------------------------------------------------------------
    account: {
        validateEmailUrl() { return gConfig.url + "/account/validateEmail".format() },

        registrationUrl() { return gConfig.url + "/account/registration" },
        loginUrl() { return gConfig.url + "/account/login" },
        autologinUrl() { return gConfig.url + "/account/autologin" },
        logoutUrl() { return gConfig.url + "/account/logout" },
        resetPwdUrl() { return gConfig.url + "/account/resetpwd" },
        getAccountDetailUrl() { return gConfig.url + "/account/getaccountdetail" },
        getUpdateAccountUrl() { return gConfig.url + "/account/updateaccountdetail" },
        changePwdUrl() { return gConfig.url + "/account/changepwd" }, 
        getAccountsUrl() { return gConfig.url + "/account/getAccounts" },
        checkResetPwdUrl() { return gConfig.url + "/account/checkResetPwd" },
        changePwdFromResetUrl() { return gConfig.url + "/account/changePwdFromReset" },
        //getAccountOverviewUrl() { return gConfig.url + "/account/getAccountOverview" },
        //tourFinishedUrl() { return gConfig.url + "/account/tourFinished" },
        manageMfaUrl() { return gConfig.url + "/account/manageMfa" },
        loginForDemoUrl() { return gConfig.url + "/account/loginForDemo" },
    },
    //-----------------------------------------------------------------------------------
    billingEntity: {
        createBillingEntityUrl() { return gConfig.url + "/billingEntity/createBillingEntity" },
        deleteBillingEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/deleteBillingEntity".format(beID) },
        renameBillingEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/renameBillingEntity".format(beID) },
        getBillingEntitiesUrl() { return gConfig.url + "/billingEntity/getBillingEntities" },
        getBillingProfilesUrl(beID) { return gConfig.url + "/billingEntity/{0}/getBillingProfiles".format(beID) },
        billingProfileUpdateUrl(beID) { return gConfig.url + "/billingEntity/{0}/billingProfileUpdate".format(beID) },

        getSubscriptionsUrl(beID) { return gConfig.url + "/billingEntity/{0}/getSubscriptions".format(beID) },
        getDownloadInvoicesForSubscriptionUrl(beID) { return gConfig.url + "/billingEntity/{0}/downloadInvoicesForSubscription".format(beID) },
        createSubscriptionUrl(beID) { return gConfig.url + "/billingEntity/{0}/createSubscription".format(beID) },
        cancelSubscriptionUrl(beID) { return gConfig.url + "/billingEntity/{0}/cancelSubscription".format(beID) },
        //sendPaymentDetailUrl(beID) { return gConfig.url + "/billingEntity/{0}/sendPaymentDetail".format(beID) },
        activateTrialSubscriptionUrl(beID) { return gConfig.url + "/billingEntity/{0}/activateTrialSubscription".format(beID) },
        paySubscriptionUrl(beID) { return gConfig.url + "/billingEntity/{0}/paySubscription".format(beID) },
        checkSubscriptionPaymentUrl(beID) { return gConfig.url + "/billingEntity/{0}/checkSubscriptionPayment".format(beID) },


        getEntities4BeUrl(beID) { return gConfig.url + "/billingEntity/{0}/getEntities".format(beID) },
        createEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/createEntity".format(beID) },
        deleteEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/deleteEntity".format(beID) },
        getRenameEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/renameEntity".format(beID) },

        getRolesForBillingEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/getRolesForBillingEntity".format(beID) },
        removeAccountFromBillingEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/removeAccountFromBillingEntity".format(beID) },
        addAccountToBillingEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/addAccountToBillingEntity".format(beID) },
        isVATNumberValidUrl() { return gConfig.url + "/billingEntity/isVATNumberValid" },


        getRolesForEntitiesUrl(beID) { return gConfig.url + "/billingEntity/{0}/getRolesForEntities".format(beID) },
        removeAccountFromEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/removeAccountFromEntity".format(beID) },
        addAccountToEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/addAccountToEntity".format(beID) },
        editAccountToEntityUrl(beID) { return gConfig.url + "/billingEntity/{0}/editAccountToEntity".format(beID) },
    },
    //-----------------------------------------------------------------------------------
    entity: {
        validateDomainUrl(eID) { return gConfig.url + "/entity/validateDomain".format(eID) },

        uploadHvsUrl(eID) { return gConfig.url + "/entity/{0}/uploadHvs".format(eID) },
        //getEntityUrl(eID) { return gConfig.url + "/entity/{0}/getEntity".format(eID) },

        getHoaxVictimsUrl(eID) { return gConfig.url + "/entity/{0}/getHoaxVictims".format(eID) },

        setupCheckUrl(eID) { return gConfig.url + "/entity/{0}/setupCheck".format(eID) },
        listHvIsValidUrl(eID) { return gConfig.url + "/entity/{0}/listHvIsValid".format(eID) },
        customizationIsValidUrl(eID) { return gConfig.url + "/entity/{0}/customizationIsValid".format(eID) },
        whitelistingIsValidUrl(eID) { return gConfig.url + "/entity/{0}/whitelistingIsValid".format(eID) },


        domain: {
            createDomainUrl(eID) { return gConfig.url + "/entity/{0}/domain/createDomain".format(eID) },
            getDomainsUrl(eID) { return gConfig.url + "/entity/{0}/domain/getDomains".format(eID) },
            deleteDomainsUrl(eID) { return gConfig.url + "/entity/{0}/domain/deleteDomains".format(eID) },
            setTestEmailUrl(eID) { return gConfig.url + "/entity/{0}/domain/setTestEmail".format(eID) },
            sendTestEmailUrl(eID) { return gConfig.url + "/entity/{0}/domain/sendTestEmail".format(eID) },
            getDomainBlacklistUrl(eID) { return gConfig.url + "/entity/{0}/domain/getDomainBlackList".format(eID) },
        },

        campaign: {
            getCampaignsUrl(eID) { return gConfig.url + "/entity/{0}/campaign/getCampaigns".format(eID) },
            createCampaignUrl(eID) { return gConfig.url + "/entity/{0}/campaign/createCampaign".format(eID) },
            getCampaignDetailUrl(eID) { return gConfig.url + "/entity/{0}/campaign/getCampaignDetail".format(eID) },
            deleteCampaignUrl(eID) { return gConfig.url + "/entity/{0}/campaign/deleteCampaign".format(eID) },
            changeCampaignStatusUrl(eID) { return gConfig.url + "/entity/{0}/campaign/changeCampaignStatus".format(eID) },
            renameCampaignUrl(eID) { return gConfig.url + "/entity/{0}/campaign/renameCampaign".format(eID) },
            getCampaignStatusUrl(eID) { return gConfig.url + "/entity/{0}/campaign/campaignStatus".format(eID) },
            campaignSchedulerUrl(eID) { return gConfig.url + "/entity/{0}/campaign/campaignScheduler".format(eID) },
            campaignTimelineUrl(eID) { return gConfig.url + "/entity/{0}/campaign/campaignTimeline".format(eID) },

            getHoaxVictimsUrl(eID) { return gConfig.url + "/entity/{0}/campaign/getHoaxVictims".format(eID) },
        },

        hvGroup: {
            createHvGroupUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/createHvGroup".format(eID) },
            getHvGroupsUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/getHvGroups".format(eID) },
            getHvGroupDetailUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/getHvGroupDetail".format(eID) },
            hvGroupDeleteUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/hoaxVictimGroupDelete".format(eID) },
            renameHvGroupUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/renameHvGroup".format(eID) },
            getHvGroupStatusUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/hvGroupStatus".format(eID) },
            sortEmp2GroupsUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/sortEmp2Groups".format(eID) },
            hoaxVictimGroupClearUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/hoaxVictimGroupClear".format(eID) },

            getHoaxVictimsUrl(eID) { return gConfig.url + "/entity/{0}/hvgroup/getHoaxVictims".format(eID) },
        },

        hv: {
            hoaxVictimCreateUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/HoaxVictimCreate".format(eID) },
            hoaxVictimDetailUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/HoaxVictimDetail".format(eID) },
            hoaxVictimUpdateUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/hoaxVictimUpdate".format(eID) },
            hoaxVictimsDeleteUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/hoaxVictimsDelete".format(eID) },
            hoaxVictimsRemoveGroupUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/hoaxVictimsRemoveGroup".format(eID) },
            hoaxVictimScoreDetailUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/hoaxVictimScoreDetail".format(eID) },
            allHoaxVictimsDeleteUrl(eID) { return gConfig.url + "/entity/{0}/hoaxVictim/allHoaxVictimsDelete".format(eID) },     
        },

        campBlockGroup: {
            getCampaignBlockGroupsUrl(eID) { return gConfig.url + "/entity/{0}/campaignblockgroup/getCampaignBlockGroups".format(eID) },
            getCampaignBlockGroupDetailUrl(eID) { return gConfig.url + "/entity/{0}/campaignblockgroup/getCampaignBlockGroupDetail".format(eID) },
            createCampaignBlockGroupUrl(eID) { return gConfig.url + "/entity/{0}/campaignblockgroup/createCampaignBlockGroup".format(eID) },
            deleteCampaignBlockGroupUrl(eID) { return gConfig.url + "/entity/{0}/campaignblockgroup/deleteCampaignBlockGroup".format(eID) },
        },

        campBlock: {
            sendSampleUrl(eID) { return gConfig.url + "/entity/{0}/campaignblock/sendSample".format(eID) },
            getCampaignBlocksUrl(eID) { return gConfig.url + "/entity/{0}/campaignblock/getCampaignBlocks".format(eID) },
        },

        report: {
            getReportsUrl(eID) { return gConfig.url + "/entity/{0}/report/getReports".format(eID) },
            getDownloadReportUrl(eID) { return gConfig.url + "/entity/{0}/report/downloadReport".format(eID) },
            getEntityReportUrl(eID) { return gConfig.url + "/entity/{0}/report/getEntityReport".format(eID) },
            getHvGroupReportUrl(eID) { return gConfig.url + "/entity/{0}/report/getHvGroupReport".format(eID) },
            getHoaxVictimReportUrl(eID) { return gConfig.url + "/entity/{0}/report/getHoaxVictimReport".format(eID) },
            getEntityGroupsReportUrl(eID) { return gConfig.url + "/entity/{0}/report/getEntityGroupsReport".format(eID) },
            downloadEntityGroupsReportUrl(eID) { return gConfig.url + "/entity/{0}/report/downloadEntityGroupsReport".format(eID) },

            getHoaxVictimsByHvGroupUrl(eID) { return gConfig.url + "/entity/{0}/report/getHoaxVictimsByHvGroup".format(eID) },
            getHoaxVictimsByEntityUrl(eID) { return gConfig.url + "/entity/{0}/report/getHoaxVictimsByEntity".format(eID) },

            hoaxVictimResultsUrl(eID) { return gConfig.url + "/entity/{0}/report/hoaxVictimResults".format(eID) },
            hoaxVictimResultTimeLineUrl(eID) { return gConfig.url + "/entity/{0}/report/hoaxVictimResultTimeline".format(eID) },

        },

        lazyai: {
            changeLazyAIStatusUrl(eID) { return gConfig.url + "/entity/{0}/lazyAI/changeLazyAIStatus".format(eID) },
            getLazyAIStatusUrl(eID) { return gConfig.url + "/entity/{0}/lazyAI/getLazyAI".format(eID) },
            updateLazyAISettingsUrl(eID) { return gConfig.url + "/entity/{0}/lazyAI/updateLazyAISettings".format(eID) },
        },

        training: {
            getTrainingContentsUrl(eID) { return gConfig.url + "/entity/{0}/training/getTrainingContents".format(eID) },
            getTrainingQuestionsUrl(eID) { return gConfig.url + "/entity/{0}/training/getTrainingQuestions".format(eID) },

            getTrainingPortalSampleUrlUrl(eID) { return gConfig.url + "/entity/{0}/training/getTrainingPortalSampleUrl".format(eID) },
        },

        customization: {
            updateCustomDataUrl(eID) { return gConfig.url + "/entity/{0}/customization/updateCustomData".format(eID) },
            getCustomDataUrl(eID) { return gConfig.url + "/entity/{0}/customization/getCustomData".format(eID) },
        },
        
    },

    //##################################################################################

    getLoadingCfg(container, zindex) {
        if (zindex == undefined || zindex == null) zindex = 1050;

        return {
            container: container, 
            color: '#51cbce',
            loader: 'spinner',
            //height: *,
            //width: *,
            zIndex: zindex,
        };
    },

    get maxCampainNameLength() { return 30; },
    get maxHvGroupNameLength() { return 100; },
    get maxEntityNameLength() { return 30; },
    get maxBillingEntityNameLength() { return 30; },
    get maxDomainNameLength() { return 255; },
    get startDateCampaignRange() { return 3; }, // in years

    get maxCustomNameLength() { return this.maxEntityNameLength; },
    get maxCustomAbbreviationLength() { return 15; },

    get maxCampainBlockGroupNameLength() { return 30; },
    get maxCampainBlockGroupDescLength() { return 200; },

    get minCampainBlockSendingDay() { return 5; },
    get maxCampainBlockSendingDay() { return 10; },
    get minCampainBlockGroupWaitingDay() { return 5; },
    get maxCampainBlockGroupWaitingDay() { return 30; },
    get maxBlocksInCampainBlockGroup() { return 12; },

    get maxHvsInHVGForQACampain() { return 15; },

    get searchWaitingAction() { return 1000; },


    get passwordRegex() { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/ },

    get alertTimer15() { return 15000; },

    get getSweetAlertCfg_YesNo() {
        return {
            icon: 'question',
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: i18n.t('general.yesNoAlert.yesLabel'),
            cancelButtonText: i18n.t('general.yesNoAlert.noLabel'),
            focusCancel: true,
            cancelButtonColor: '#ef8157',
            confirmButtonColor: '#6bd098',
        };
    },

    get getSweetAlertCfg_Waiting() {
        return {
            showConfirmButton: false,
            icon: 'info',
            iconHtml: `<i class="fa-solid fa-spinner fa-pulse fa-fw"></i>`,
            title: i18n.t('general.waitingAlert.msg'),
            text: i18n.t('general.waitingAlert.text'),
            allowOutsideClick: false,
        };
    },

    get defaultCustomColor() {
        return "#909090";
    },

    dashboardInfoUrl(cc) {
        let url = "https://prodlpstorage.blob.core.windows.net/public/helpsupport/dashboardInfo.{0}.json";
        if (this.envName === "dev" || this.envName === "devAzure")
            url = "https://devlpstorage.blob.core.windows.net/public/helpsupport/dashboardInfo.{0}.json";

        if (this.envName === "test")
            url = "https://testlpstorage.blob.core.windows.net/public/helpsupport/dashboardInfo.{0}.json";

        return url.format(cc);
    },

    get globalBenchmark() {
        let url = "https://prodlpstorage.blob.core.windows.net/public/helpsupport/globalBenchmark.json";
        if (this.envName === "dev" || this.envName === "devAzure")
            url = "https://devlpstorage.blob.core.windows.net/public/helpsupport/globalBenchmark.json";

        if (this.envName === "test")
            url = "https://testlpstorage.blob.core.windows.net/public/helpsupport/globalBenchmark.json";

        return url;
    },

    get defaultCustomLogoUrl() {
        if (this.envName === "dev" || this.envName === "devAzure") {
            return "https://devlpstorage.blob.core.windows.net/public/customization/logo.png";
        }
        if (this.envName === "test") {
            return "https://testlpstorage.blob.core.windows.net/public/customization/logo.png";
        }
        return "https://prodlpstorage.blob.core.windows.net/public/customization/logo.png";
    },

    get supportedPhoneNumberCountries() {
        return ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB" ];
    },


    get areasDefinition() {
        return [
            { key: "Email", values: ["EmailSendingProfile", "EmailTemplate"] },
            { key: "SMS", values: ["SmsSendingProfile", "SmsTemplate"] },
            { key: "Landing Page", values: ["LandingPage"] },
            { key: "General", values: ["General"] },
        ];
    },

    get paramsDefinition() {
        return [
            { key: "Sender", values: ["SenderAddr", "SenderName", "SenderNumber"] },
            { key: "Wording", values: ["Subject", "Text"] },
            { key: "Design", values: ["Design"] },
            { key: "Link", values: ["Link", "URLAddr"] },
            { key: "Urgency", values: ["Urgency"] },
        ];
    },

    get emailDomains() {
        return [
            "mail-service.cloud",
            "accountservice.cloud"
        ];
    },
}

export default gConfig