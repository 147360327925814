<template>
    <div class="entity-switch" data-v-step="sb-entity-menu-switch">
        <span class="be-name mb-2" data-v-step="sb-org-name">
            <span class="line"></span>
            <el-tooltip :content="billingCompany" :open-delay="300" placement="top" v-if="enableTooltip">
                <span class="label"> {{billingCompany}}</span>
            </el-tooltip>
            <span class="label" v-else> {{billingCompany}}</span>
            <span class="line"></span>
        </span>
        <div class="switch" data-v-step="sb-entity-switch">
            <el-select size="large" @change="entityChange"
                       v-model="entityHID" filterable 
                       :placeholder="$t('entitySwitch.selectPlaceholder')"
                       class="select-default dark">
                <el-option-group v-for="group in groupedEntities" class="bold-title"
                                 :key="group.beHID"
                                 :label="group.beName">
                    <el-option v-for="ent in group.entities"
                               :key="ent.hid"
                               :label="ent.name"
                               :value="ent.hid"
                               :disabled="ent.beIsDisabled">
                        <span style="float: left">
                            <el-tooltip content="No subscription" :open-delay="300" placement="top" v-if="!ent.hasActiveSub">
                                <i class="mr-1 fa fa-exclamation text-danger" aria-hidden="true"></i>
                            </el-tooltip>
                            <el-tooltip content="Blocked" :open-delay="300" placement="top" v-if="ent.beIsDisabled">
                                <i class="mr-1 fa fa-lock text-danger" aria-hidden="true"></i>
                            </el-tooltip>
                            {{ ent.name }}
                        </span>
                        <span style="float: right">
                            <el-tooltip :content="roleTooltip(ent.role)" :open-delay="300" placement="top">
                                <i :class="role.getRoleIcon(ent.role)" aria-hidden="true"></i>
                            </el-tooltip>
                        </span>
                    </el-option>
                </el-option-group>
            </el-select>
        </div>
    </div>
</template>
<script>
    import { CollapseTransition } from 'vue2-transitions'
    import { Select, Option, OptionGroup } from 'element-ui'
    import { RoleEnum } from 'src/lpLibrary.js'
    import swal from 'sweetalert2'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            CollapseTransition,
            [Option.name]: Option,
            [OptionGroup.name]: OptionGroup,
            [Select.name]: Select,
        },
        mixins: [{ data() { return { role: RoleEnum } } }],
        data() {
            return {
                isClosed: true,
                entityHID: null,
            }
        },
        methods: {
            entityChange(hid) {
                let entity = this.entities.find(c => c.hid == hid);
                if (!entity.hasActiveSub) {
                        swal.fire({
                            icon: 'error',
                            title: this.$t('entitySwitch.noActiveSub.msg', [entity.beName]),
                            text: this.$t('entitySwitch.noActiveSub.text'),
                            showConfirmButton: true,
                        });
                    this.entityHID = null;
                    if (this.$store.getters.appState.entity != undefined) {
                        this.entityHID = this.$store.getters.appState.entity.hid
                    }
                    return;
                }
                this.$store.commit('selectEntity', entity);
                let route = this.$router.currentRoute;
                if (this.$route.path.startsWith("/entity")) {
                    this.$router.replace({ name: route.name, params: { ehid: hid } });
                }
            },
            roleTooltip(role) {
                return role;
            }
        },
        created: function () {
            if (this.$store.getters.appState.entity != null)
                this.entityHID = this.$store.getters.appState.entity.hid;
        },
        computed: {
            entities() {
                return this.$store.getters.userState.entities;
            },
            groupedEntities() {
                if (this.entities == undefined) return [];
                let gEnt = [];
                for (let i = 0; i < this.entities.length; i++) {
                    let ent = this.entities[i];
                    let gE = gEnt.find(c => c.beHID === ent.beHID);
                    if (gE == undefined) {
                        gE = {
                            beHID: ent.beHID,
                            beName: ent.beName,
                            entities: []
                        };
                        gEnt.push(gE);
                    }
                    //----- overeni active sub
                    if (ent.subscriptionExpiration != null) {
                        ent.hasActiveSub = true;
                    }
                    gE.entities.push(ent);
                    gE.entities.sort(function (a, b) { return sortingHandler(a, b, { order: 'ascending', name: 'name' }); })
                }

                let result = gEnt.sort(function (a, b) { return sortingHandler(a, b, { order: 'ascending', name: 'beName' }); });

                if (result.length == 1 && this.entityHID == null) {
                    let entity = this.entities[0];
                    if (entity.hasActiveSub) {
                        this.$store.commit('selectEntity', entity);
                        let route = this.$router.currentRoute;
                        if (this.$route.path.startsWith("/entity") && this.$route.params.ehid != entity.hid) {
                            this.$router.replace({ name: route.name, params: { ehid: entity.hid } });
                        }
                    }
                }
                return result;
            },
            billingCompany() {
                if (this.entityHID == null || this.entities == undefined) return this.$t('entitySwitch.bePlaceholder');
                return this.entities.find(c => c.hid == this.entityHID).beName;
            },
            enableTooltip() {
                return this.billingCompany.length > 25;
            },
        },
        watch: {
            '$store.getters.appState.entity': function () {
                if (this.$store.getters.appState.entity == null) {
                    this.entityHID = null;
                } else if (this.entityHID != this.$store.getters.appState.entity.hid) {
                    this.entityHID = this.$store.getters.appState.entity.hid;
                }
            },
        }
    }
</script>
<style scoped>

    .nav.nav-menu {
        margin-top: 0;
    }
</style>
